<template>
  <div>
    <Header />
    <Hero class="mb-5" />
    <Sponsors />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Sponsors from '@/components/Sponsors.vue';
import Hero from '@/components/Hero.vue';

export default {
  components: {
    Header,
    Sponsors,
    Hero,
  },
};
</script>

<style>
</style>
