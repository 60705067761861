<template>
  <div id="login-page" class="container">
    <Hero class="mb-4" />
    <section class="login d-flex align-items-center justify-content-center">
      <div id="login-box">
        <form id="login-form" class="form" @submit.prevent="login">
          <h3 class="text-center mb-4">Giriş Yap</h3>
          <div class="form-group">
            <input
              type="text"
              placeholder="E-posta"
              name="email"
              id="username"
              class="form-control"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              placeholder="Parola"
              name="password"
              id="password"
              class="form-control"
              v-model="password"
            />
          </div>
          <div class="form-group">
            <label for="remember-me">
              <span>
                <input id="remember-me" name="remember-me" type="checkbox" />
              </span>
              <span>
                <a href="#" data-toggle="modal" data-target="#login-modal">
                  KVKK ve Aydınlatma metinlerini
                </a>
                okudum. Kabul ediyorum.</span
              >
            </label>
          </div>
          <b-button
            type="submit"
            name="submit"
            class=""
            block
            size="lg"
            variant="primary"
            v-if="!loginPending"
            >Giriş</b-button
          >
          <b-button
            variant="primary"
            class="btn-md mt-3"
            disabled
            block
            size="lg"
            v-if="loginPending"
          >
            <b-spinner small></b-spinner>
            Giriş yapılıyor...
          </b-button>

          <div class="alert alert-light text-center" role="alert">
            {{ error }}
          </div>
        </form>
        <p>Şifre: rader</p>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Hero from '@/components/Hero.vue';
import { mapGetters } from 'vuex';
import { auth, database } from '../firebase';

export default {
  data() {
    return {
      email: '',
      password: '',
      eventId: 'H6h55m6joIt2j5M1cxYm',
      error: '',
      loginPending: false,
    };
  },
  components: {
    Hero,
  },
  computed: {
    ...mapGetters({
      eventCurrentPath: 'event/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  methods: {
    async login() {
      this.loginPending = true;
      try {
        // token elde etmek için backend'e request yolla
        const tokenResponse = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_BASE_URL}/login`,
          data: {
            email: this.email,
            password: this.password,
            event: this.eventId,
          },
        });
        const { token } = tokenResponse.data;

        // gelen token'la firebase auth'a giriş yap
        console.log('login with new token');
        await auth().signInWithCustomToken(token);
        localStorage.setItem('t', token);
        this.loginPending = false;
        this.$router.push('/');
      } catch (error) {
        console.log({ error });

        if (error.response) {
          if (error.response.status !== 500) {
            this.error = error.response.data.message;
          } else {
            this.error = 'Bilinmeyen bir hata oluştu';
          }
        } else {
          this.error = 'Bilinmeyen bir hata oluştu';
        }
        this.loginPending = false;
      }
    },
  },
  created() {
    console.log(this.currentPeople);
    if (this.currentPeople) {
      console.log('login: make offline');
      const userStatusDatabaseRef = database().ref(
        `/attendees/${this.currentEvent.id}/${this.currentPeople.id}`,
      );

      // eğer user yoksa realtime database'de kaydı offline yap
      userStatusDatabaseRef.update({ status: 'offline' });
    } else {
      console.log('no people');
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em;
  vertical-align: middle;
}
</style>
