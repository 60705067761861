<template>
  <div id="video-page" :style="bgStyle">
    <!-- eslint-disable max-len -->
    <router-link
      class="btn btn-light"
      to="/stand"
      style="top: 5px; right: 5px; position: absolute"
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-box-arrow-right"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
        />
        <path
          fill-rule="evenodd"
          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
        />
      </svg>
      Çıkış</router-link
    >
    <iframe
      :src="iframeSrc"
      width="854"
      height="1100"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import bgImg from '@/assets/images/bg_video.jpg';
import { database } from '../firebase';

export default {
  data() {
    return {
      bgStyle: { backgroundImage: '' },
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
      currentEventPath: 'event/currentPath',
      currentStage: 'stage/current',
      currentStagePath: 'stage/currentPath',
      liveSession: 'agenda/liveSession',
    }),
    sessionPath() {
      return `${this.currentStagePath}/session`;
    },
    iframeSrc() {
      const location = this.currentPeople.location ? this.currentPeople.location : '';
      return `https://event.livecasthq.com/raderguzlive/embed/?uid=${this.currentPeople.email}&name=${this.currentPeople.name}&location=${location}`;
    },
  },
  methods: {
    ...mapActions({
      getStage: 'stage/get',
      setCurrentStage: 'stage/setCurrent',

      findAgenda: 'agenda/find',
      clearState: 'agenda/clearItems',
    }),
    updateBgImg(newSession) {
      if (newSession.bgImg) {
        this.bgStyle.backgroundImage = `url('${newSession.bgImg}')`;
      } else {
        this.bgStyle.backgroundImage = `url('${bgImg}')`;
      }
    },
  },
  watch: {
    liveSession(newSession) {
      if (newSession) {
        this.updateBgImg(newSession);

        const attendeeRef = database().ref(`/attendees/${this.currentEvent.id}/${this.currentPeople.id}/sessions/${newSession.id}`);

        const payload = {
          lastJoin: database.ServerValue.TIMESTAMP,
        };

        attendeeRef.once('value').then((sn) => {
          if (!sn.exists()) {
            payload.firstJoin = database.ServerValue.TIMESTAMP;
          }
          attendeeRef.update(payload);
        });
      }
    },
  },
  created() {
    /* eslint-disable max-len */
    this.clearState();
    this.getStage({
      path: `${this.currentEventPath}/stage`,
      id: this.$route.params.id,
    }).then((stage) => {
      this.setCurrentStage(stage);
      this.findAgenda({ path: this.sessionPath, subscribe: true, order: { field: 'start', desc: false } }).then(() => {
        this.isReady = true;
      });
    });
  },
};
</script>

<style lang="scss">
#video-page {
  //background-image: url('../images/bg_video.jpg');
  background-color: #101010;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../assets/images/bg_video.jpg");
  display: block;
  width: 100vw;
  height: calc(100vw / 1.77);
  background-position: center;

  iframe {
    position: absolute;
    width: 47.5%;
    left: 50%;
    top: calc(100vw / (1.77 * 9));
    /*margin: 2.3% auto;*/
    transform: translate(-50%, 0);
  }
}

/* Portrait */
@media (min-device-width: 375px) and (orientation: portrait) {
  #video-page {
    background-position-y: 3rem;
    height: 100vh;

    iframe {
      width: 90%;
      /*width: 47%;
      position: absolute;
      top: 10%;
      left: 50%;
      margin: -5.5% 0 0 -23.5% !important;*/
    }
  }
}

/* Landscape */
@media (max-device-width: 812px) and (orientation: landscape) {
  #video-page {
    background-position-y: 3rem;

    iframe {
      width: 47%;
      position: absolute;
      top: 14%;
      left: 50%;
      margin: 5.5% 0 0 0 !important;
    }
  }
}
</style>
