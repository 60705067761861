<template>
  <div>
    <div
      class="cert-wrapper attendee-cert"
      v-if="currentPeople.type != 'speaker'"
    >
      <span class="people-name">{{ currentPeople.name }}</span>
    </div>
    <div
      class="cert-wrapper speaker-cert"
      v-if="currentPeople.type == 'speaker'"
    >
      <span class="people-name">{{ currentPeople.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentPeople: 'people/current',
    }),
  },
  created() {
    console.log(this.currentPeople);
  },
};
</script>

<style lang="scss">
.cert-wrapper {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background-position: center;
  max-width: calc(100vh * 1.4);
  margin: auto;
}
.attendee-cert {
  background-image: url("../assets/images/rader_guz_sertifika.png");
}
.speaker-cert {
  background-image: url("../assets/images/rader_guz_sertifika_speaker.png");
}
.people-name {
  font-size: calc(100vh / 15);
  padding-bottom: calc(100vh / 15);
  color: #000;
  font-style: italic;
  font-weight: 600;
  font-family: serif;
}
</style>
