<template>
  <div>
    <Header />
    <Hero class="mb-5" />
    <Agenda />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Agenda from '@/components/Agenda.vue';
import Hero from '@/components/Hero.vue';

export default {
  components: {
    Header,
    Agenda,
    Hero,
  },
};
</script>

<style>
</style>
