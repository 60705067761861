<template>
  <div class="stand">
    <Header />
    <div class="container wide mt-4">
      <div class="box">
        <div class="row">
          <div class="col-md-12">
            <img
              class="img-fluid"
              src="/img/RADER_GuzOkulu-01.jpg"
              alt="Stand Banner"
            />
          </div>
        </div>
        <div class="row p-4">
          <div class="col-md-7">
            <div class="embed-responsive embed-responsive-4by3">
              <iframe
                src="https://player.vimeo.com/video/484039483"
                width="640"
                height="480"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>

            <!--
            <p class="mt-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.<br />
              Pariatur vero sequi esse ad provident deleniti in eum quis,
              aperiam quod quia.
            </p>
            -->
          </div>
          <div class="col-md-5 stand-buttons">
            <div>
              <!--
              <a href="#">
                <img src="@/assets/images/video-call.svg" alt="Canlı Görüşme" />
                <span>Canlı Görüşme</span>
              </a>
              -->
              <a href="https://www.abbvie.com.tr/" target="_blank">
                <img src="@/assets/images/internet.svg" alt="Web Sitesi" />
                <span>
                  Web Sitesi <small>https://www.abbvie.com.tr/</small></span
                >
              </a>
              <a href="/pdf/Humira_Stand_eBrosur_Etkililik.pdf" target="_blank">
                <img src="@/assets/images/product.svg" alt="Ürün Bilgisi" />
                <span>Humira E-Broşür</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { database } from '@/firebase';
import { mapGetters } from 'vuex';

export default {
  components: {
    Header,
  },
  computed: {
    ...mapGetters({
      eventCurrentPath: 'event/currentPath',
      currentPeople: 'people/current',
      currentAccount: 'account/current',
      currentEvent: 'event/current',
    }),
  },
  created() {
    console.log();
    if (this.currentPeople) {
      const userStatusDatabaseRef = database()
        .ref(`/attendees/${this.currentEvent.id}/${this.currentPeople.id}/boothVisit/QxjYhen90Od59dfwqqu9`);

      const payload = {
        lastJoin: database.ServerValue.TIMESTAMP,
      };
      userStatusDatabaseRef.once('value').then((snapshot) => {
        if (!snapshot.exists()) {
          payload.firstJoin = database.ServerValue.TIMESTAMP;
        }
        userStatusDatabaseRef.update(payload);
      });
    }
  },
};
</script>

<style>
</style>
